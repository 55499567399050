.faq__details__title{
    margin: 40px auto 30px auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.faq__details__title h5{
    font-family: Lora,serif;
    line-height: 1.73;
    letter-spacing: .5px;
    color: #626881;
}
.faq__details__title h2{
    font-family: 'Paperpillar' !important;
    font-size: larger;
    color: #626881;
}
.faq__details{
    margin: 40px auto 30px auto;
    width: 80%;
}
.faq__details p{
    font-family: Lora,serif;
    color: #626881;
}
.faq__details .question{
    font-family: Poppins,sans-serif;
    font-weight: bold;

}
.faq__details .answer{

    font-family: Poppins,sans-serif;
    letter-spacing: .5px;

}