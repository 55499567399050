.contact__details_title{
    margin: 40px auto 30px auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.contact__details_title h5{
    font-family: Lora,serif;
    line-height: 1.73;
    letter-spacing: .5px;
    color: #626881;
}
.contact__details_title h2{
    font-family: 'Paperpillar' !important;
    font-size: larger;
    color: #626881;
}


.contact__container{
    width: 60%;
    display: grid;
    grid-template-columns: 32% 58%;
    gap:6rem;
}
.contact__option{
    border-radius: 1rem;
    padding:1.4rem;
    text-align: center;
    transition: all 400ms ease;
    border-color:rgba(77,181,244,0.4);
    box-shadow: 0 6px 10px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    margin:1.4rem;
    object-fit: cover;
    transition: all 0.2s linear;
}
.contact__option:hover{
    transform: scale(1.1);
}

.contact__option-icon{
    color: #4db5ff;
    font-size: 1.4rem;
}
.contact__option h4{
    font-family: 'Paperpillar' !important;
    font-size: larger;
    color: #626881
}
.contact__option h5{
    font-family: Lora,serif;
    line-height: 1.73;
    letter-spacing: .5px;
    color: #626881;
}
.contact__option a{
    text-decoration: none;
    color: #4db5ff;
}

form {
    margin-top: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

input, textarea{
    width: 100%;
    padding:1.5rem;
    border-radius: .5rem;
    background: transparent;
    border: 1px solid rgba(77,181,244,0.4);
    resize: none;
    color:var(--color-white);
    font-size: 1.1rem;
}

@media screen and (max-width:1024px) {
    .contact__container{
        width: 90%;
        display: grid;
        grid-template-columns: 1fr;
        gap:2rem;
    }
  
}

@media screen and (max-width:600px) {
    .contact__container{
        gap:2rem;
    }

}